<template>
  <ol class="day-of-week">
    <li
      class="work-day-text"
      :class="{ 'not-work-day-text': [5, 6].includes(index) }"
      v-for="(weekday, index) in weekdays"
      :key="weekday"
    >
      <div>
        {{ weekday }}
      </div>
    </li>
  </ol>
</template>

<script>
const WEEKDAYS = ["ПН", "ВТ", "СР", "ЧТ", "ПТ", "СБ", "ВС"];

export default {
  name: "CalendarWeekdays",

  computed: {
    weekdays() {
      return WEEKDAYS;
    }
  }
};
</script>

<style scoped>
.day-of-week {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
}

.day-of-week > * {
  text-align: center;
  padding-right: 5px;
}
ol,
li {
  padding: 0;
  margin: 0;
  list-style: none;
}

.work-day-text {
  font-family: Inter;
  font-style: normal;
  font-weight: 800;
  font-size: 12px;
  line-height: 68px;
  /* or 717% */

  color: #95c23d;
}

.not-work-day-text {
  font-family: Inter;
  font-style: normal;
  font-weight: 800;
  font-size: 12px;
  line-height: 68px;
  /* or 717% */
  color: #6d778e;
}

.work-day-text div {
  /*height: 68px;*/
}
</style>
