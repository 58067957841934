<template>
  <MainLayout>
    <div
      class="list list_radius-all container list_overflow_visible d-flex flex-column"
      style="background-color: #ffffff"
    >
      <div>
        <div>
          <h6 class="px-8 py-8 font-weight-bold " style="color: #515A6E;">
            Выберите группу пользователей для настройки рабочего графика
          </h6>
          <div class="filter filter_padding">
            <div class="filter__inner">
              <form class="filter__form">
                <ValidationAutocompleteField
                  v-model="selectedGroup"
                  :search-function="searchGroups"
                  label="Группа пользователей"
                />

                <div>
                  <a
                    style="width: 232px;"
                    class="btn btn_color_white btn_results"
                    @click="showResult"
                    >показать результаты
                  </a>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div class="mb-4">
        <div>
          <h6 class="px-8 pb-8 font-weight-bold " style="color: #515A6E;">
            Группа пользователей “Охрана”, всего 12 пользователей
          </h6>
          <div class="d-flex flex-row px-8">
            <div class="d-flex mr-5 type-text">
              <div
                style="width: 25px; height: 25px; background: #95C23D; border-radius: 4px;"
                class="mr-3"
              ></div>
              <p>Рабочий день</p>
            </div>
            <div class="d-flex type-text">
              <div
                style="background: #6D778E; border-radius: 4px; width: 25px; height: 25px;"
                class="mr-3"
              ></div>
              <p>Выходной</p>
            </div>
            <div class="d-flex  ml-auto">
              <button class="btn btn-edit btn_color_grey mr-5">
                Настроить рабочий график
              </button>
            </div>
          </div>
        </div>
      </div>
      <div style="background-color: #F5F5F5; height: 5px; width: 100%;"></div>
      <div v-if="this.selectedGroup && worktime.length > 0">
        <div class="my-5 d-flex justify-center">
          <!--        <span class="date-piker-selector">{{-->
          <!--          selectedDate.format("MMMM YYYY")-->
          <!--        }}</span>-->
          <div style="width: 150px;">
            <v-menu
              ref="menu"
              v-model="menu"
              nudge-left="65"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              min-width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  :value="computedDateFormattedMomentjs"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                color="#95c23d"
                @input="selectNewMonth"
                type="month"
              ></v-date-picker>
            </v-menu>
          </div>
        </div>
      </div>
    </div>
    <div class="pt-7" style="background-color: #F5F5F5;">
      <div v-if="this.selectedGroup && worktime.length > 0">
        <div class="d-flex flex-row">
          <div
            style="width: 555px;
            background-color: #ffffff;
            margin-left: 80px;
box-shadow: 0px 1px 3px rgba(37, 65, 106, 0.1);
border-radius: 8px;
            margin-right: auto;margin-bottom: 60px;"
          >
            <CalendarWeekdays />
            <div
              style="height: 5px;
              background: #F4F4F4;
              box-shadow: inset 0px 1px 3px rgba(81, 90, 110, 0.08);"
            />
            <ol class="days-grid">
              <CalendarMonthDayItem
                v-for="day in days"
                :key="day.date.unix()"
                :day="day"
                :is-today="currentDay.isSame(day.date, 'd')"
              />
            </ol>
          </div>
          <div
            style="width: 555px;
            margin-left: auto;
            margin-right: 80px;
            border-radius: 8px;"
          >
            <div
              style="
box-shadow: 0px 5px 10px rgba(37, 65, 106, 0.1);
border-radius: 8px;
            background-color: #ffffff;"
              class="pa-6 d-flex flex-row justify-lg-space-between"
            >
              <div class="save-text">Сохраненные дни</div>
              <div class="save-date-text">
                Дата настройки 05.12.2019, в 18:37:19
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </MainLayout>
</template>

<script>
import MainLayout from "@/components/layouts/MainLayout";
import ValidationAutocompleteField from "@/components/ValidationField/ValidationAutocompleteField";
import { getAccessGroupsClearRequest } from "@/helpers/api/access-group";
import moment from "moment";
import Axios from "axios";
import { getQueriedUrl } from "@/helpers/queryStringHelper";
import CalendarWeekdays from "@/components/Calendar/CalendarWeekdays";
import CalendarMonthDayItem from "@/components/Calendar/CalendarMonthDayItem";

export default {
  name: "WorkingTimeSetting",
  components: {
    CalendarMonthDayItem,
    CalendarWeekdays,
    ValidationAutocompleteField,
    MainLayout
  },
  data() {
    return {
      searchGroups: async param => {
        return (
          await getAccessGroupsClearRequest({
            query: { ...this.urlQuery.query, search: param, offset: null }
          })
        ).data.results.map(el => ({ value: el.id, text: el.title }));
      },
      selectedDate: moment(),
      selectedGroup: null,
      menu: false,
      worktime: []
    };
  },
  methods: {
    selectNewMonth(date) {
      this.selectedDate = moment(date);
      this.menu = false;
      this.showResult();
    },
    showResult() {
      Axios.get(
        getQueriedUrl({
          url: "/worktime/",
          query: {
            group: this.selectedGroup,
            year: this.currentDay.get("year"),
            month: this.currentDay.format("MM")
          }
        })
      ).then(res => {
        this.worktime = res.data.results;
      });
    }
  },
  computed: {
    computedDateFormattedMomentjs() {
      return this.selectedDate
        ? moment(this.selectedDate).format("MMMM YYYY")
        : "";
    },
    currentDay() {
      return moment();
    },
    days() {
      return [
        ...this.previousMonthDays,
        ...this.currentMonthDays,
        ...this.nextMonthDays
      ];
    },
    previousMonthDays() {
      const firstInCurrentMonth = this.currentMonthDays[0].date;
      const firstDayOfTheMonthWeekday = firstInCurrentMonth.isoWeekday();

      // Cover first day of the month being sunday (firstDayOfTheMonthWeekday === 0)
      const visibleNumberOfDaysFromPreviousMonth = firstDayOfTheMonthWeekday
        ? firstDayOfTheMonthWeekday - 1
        : 6;

      const previousMonthLastMondayDayOfMonth = firstInCurrentMonth
        .clone()
        .subtract(visibleNumberOfDaysFromPreviousMonth + 1, "day");

      return [...Array(visibleNumberOfDaysFromPreviousMonth)].map(() => {
        previousMonthLastMondayDayOfMonth.add(1, "day");
        return {
          date: previousMonthLastMondayDayOfMonth.clone(),
          info: null,
          type: null,
          isCurrentMonth: false
        };
      });
    },
    nextMonthDays() {
      const lastInCurrentMonth = this.currentMonthDays[
        this.currentMonthDays.length - 1
      ].date;

      const lastDayOfTheMonthWeekday = lastInCurrentMonth.isoWeekday();

      const visibleNumberOfDaysFromNextMonth = lastDayOfTheMonthWeekday
        ? 7 - lastDayOfTheMonthWeekday
        : lastDayOfTheMonthWeekday;

      const nextDayOfMonth = lastInCurrentMonth.clone();

      return [...Array(visibleNumberOfDaysFromNextMonth)].map(() => {
        nextDayOfMonth.add(1, "day");
        return {
          date: nextDayOfMonth.clone(),
          info: null,
          type: null,
          isCurrentMonth: false
        };
      });
    },
    currentMonthDays() {
      let startMonth = this.selectedDate.clone().startOf("month");
      let endMonth = this.selectedDate.clone().endOf("month");
      const arrDays = [];

      while (startMonth.isSameOrBefore(endMonth, "d")) {
        const currentWork = this.worktime.find(work => {
          const dayInWork = moment(work.date);
          return startMonth.isSame(dayInWork, "d");
        });
        arrDays.push({
          date: startMonth.clone(),
          info: currentWork?.info,
          type: currentWork?.type,
          isCurrentMonth: true
        });
        startMonth.add(1, "d");
      }
      return arrDays;
    },
    urlQuery() {
      return this.$store.getters.getPageQuery;
    }
  }
};
</script>

<style scoped>
.days-grid {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  padding: 0 !important;
}

.days-grid {
  grid-column-gap: var(--grid-gap);
  grid-row-gap: 11px;
  border-top: solid 1px var(--grey-200);
}

.date-piker-selector {
  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 44px;
  /* or 275% */
  text-transform: capitalize;

  color: #6d778e;
}

.save-text {
  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  /* or 125% */

  color: #515a6e;
}

.save-date-text {
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  /* or 167% */

  color: #9ba3b7;
}
.type-text {
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  /* or 167% */

  color: #515a6e;
}
</style>
